import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HomeComponent } from './home/home.component';
import { AddEditRoundComponent } from './add-edit-round/add-edit-round.component';
import { RouterModule } from '@angular/router';
import { ApiCourse } from './service/apiCourse';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddEditRoundComponent
  ],
  imports: [
    BrowserModule,
    AngularFirestoreModule, 
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot([
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', component: HomeComponent },
      { path: 'edit', component: AddEditRoundComponent }
    ])  
  ],
  providers: [ApiCourse],
  bootstrap: [AppComponent]
})
export class AppModule { }
