import { Injectable } from "@angular/core";
import { Course } from "../model/course";
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class ApiCourse {

  constructor(private store: AngularFirestore) {}

  getCourse(): Course {
    const course = new Course();
    course.name = "Glenmoor";
    const parsHC: number[] = [5,4,3,4,5,4,3,4,4,4,4,4,3,4,5,3,4,5];
    for(let index=0; index<18; index++) {
      course.numbers[index] = index + 1;
      course.pars[index] = parsHC[index];  
    }
    return course;
  }
}