export class Round {
  scores: number[] = [];
  handicap: number;

  constructor() {
    for(let x=0; x < 18; x++) {
      this.scores.push(0);
    }
    this.handicap = 18;
  }
}