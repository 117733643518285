// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyByAhQKTHIr6fOWvomNkvEljMjZEq4g9AY",
    authDomain: "flight-filler.firebaseapp.com",
    databaseURL: "https://flight-filler-default-rtdb.firebaseio.com",
    projectId: "flight-filler",
    storageBucket: "flight-filler.appspot.com",
    messagingSenderId: "498979168742",
    appId: "1:498979168742:web:2fcdb07dbdc79e60833627",
    measurementId: "G-QZX8KYRN9D"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
