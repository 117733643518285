export class Course {
  name: string;
  numbers: number[] = [];
  pars: number[] = [];

  constructor() {
    this.name = "Unnamed";
    for(let x=0; x < 18; x++) {
      this.numbers.push(0);
      this.pars.push(0);
    }
  }
}