import { Component, HostListener, OnInit, ɵCompiler_compileModuleAndAllComponentsAsync__POST_R3__ } from '@angular/core';
import { Course } from '../model/course';
import { Round } from '../model/round';
import { ApiCourse } from '../service/apiCourse';

@Component({
  selector: 'app-add-edit-round',
  templateUrl: './add-edit-round.component.html',
  styleUrls: ['./add-edit-round.component.scss']
})
export class AddEditRoundComponent implements OnInit {
  readonly validKeys = new Set<string>(['0','1','2','3','4','5','6','7','8','9']);
  round: Round = new Round();
  course: Course;
  scoreStates = [];
  frontTotal: number = 0;
  backTotal: number = 0;
  total: number = 0;
  searchText: string;

  constructor(public api: ApiCourse) { }

  ngOnInit(): void {
    this.course = this.api.getCourse();
    this.initScoreState(this.round);
  }

  private handleNewValue(newValue: string) {
    this.scoreStates.find(scoreState => scoreState.active === true).score = newValue;
    this.calcTotal();
    this.navigateScoreForward();
  }

  private navigateScoreForward() {
    this.navigate(this.scoreStates.length - 1, 0, 1);
  }

  private navigateScoreBackward() {
    this.navigate(0, this.scoreStates.length - 1, -1);
  }

  private navigate(threshold: number, newIndex: number, delta: number) {
    const activeScoreState = this.scoreStates.find(scoreState => scoreState.active === true);
    if(activeScoreState) {
      let activeIndex = activeScoreState.index as number;
      if(activeIndex == threshold) {
        activeIndex = newIndex;
      } else {
        activeIndex = activeIndex + delta;
      }
      this.clearActiveState();
      this.scoreStates.find(scoreState => scoreState.index === activeIndex).active = true;
    }
  }

  private clearActiveState() {
    this.scoreStates.forEach(scoreState => {
      scoreState.active = false
    });
  }

  private calcTotal() {
    this.calcFrontTotal();
    this.calcBackTotal();
    this.total = this.frontTotal + this.backTotal;
  }

  private calcFrontTotal() {
    this.frontTotal = this.calcTotalFor9(0,9);
  }

  private calcBackTotal() {
    this.backTotal = this.calcTotalFor9(9,18);
  }

  private calcTotalFor9(start: number, end: number): number {
    let total = 0;
    for(let index=start; index<end; index++) {
      total += +this.scoreStates.find(scoreState => scoreState.index === index).score;
    }
    return total;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    let key = event.key;
    const isValidKey = this.validKeys.has(key);
    if(isValidKey) {
      if(event.shiftKey) {
        key = "1" + key;
      }
      this.handleNewValue(key);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === "ArrowLeft") {
      this.navigateScoreBackward();
    } else if (event.key === "ArrowRight") {
      this.navigateScoreForward();
    }
  }

  private initScoreState(round: Round) {
    for(let index=0; index<round.scores.length; index++) {
      const score = round.scores[index];
      const scoreState = {
        score: score,
        active: index === 0,
        index: index 
      }
      this.scoreStates.push(scoreState);
    }
  }

  getScoreClass(scoreState): string {
    return `${this.getScoreBackgroundClass(scoreState)} ${this.getScoreTextClass(scoreState)}`
  }

  private getScoreBackgroundClass(scoreState): string {
    let classStr = "bg-info";
    if(!scoreState.active) {
      if(scoreState.score > 0) {
        classStr = "bg-success"
      } else {
        classStr = "bg-light";
      }
    }
    return classStr;    
  }

  private getScoreTextClass(scoreState): string {
    let classStr = "text-dark";
    if(!scoreState.active) {
      if(scoreState.score > 0) {
        classStr = "text-light"
      }
    }
    return classStr;
  }
}
