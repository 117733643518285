<div>
  <table class="table table-borderless text-center">
    <thead>
      <tr class="bg-dark text-light">
        <th *ngFor="let number of course.numbers | slice:0:9"
          class="w-1"
          >{{ number }}
        </th>
        <th class="w-2">TOT</th>
        <th *ngFor="let number of course.numbers | slice:9:18"
          class="w-1"
        >{{ number }}
        </th>
        <th class="w-2">TOT</th>
        <th class="w-2">TOT</th>
        <th class="w-2">HCP</th>
        <th class="w-2">NET</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let scoreState of scoreStates | slice:0:9" 
          [ngClass]="getScoreClass(scoreState)"        
          >{{ scoreState.score }}
        </td> 
        <td class="bg-secondary">{{ frontTotal }}</td>
        <td *ngFor="let scoreState of scoreStates | slice:9:18" 
          [ngClass]="getScoreClass(scoreState)"        
          >{{ scoreState.score }}
        </td>
        <td class="bg-secondary">{{ backTotal }}</td>
        <td class="bg-secondary">{{ total }}</td>
        <td>{{ round.handicap }}</td>
        <td class="bg-secondary">{{ total - round.handicap }}</td>
      </tr>
    </tbody>
  </table>
</div>
